import React, {useEffect, useState} from 'react';
import {extendTheme} from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import TableChartIcon from '@mui/icons-material/TableChart';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import {AppProvider} from '@toolpad/core/AppProvider';
import {DashboardLayout} from '@toolpad/core/DashboardLayout';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Home from './components/Home';
import ProductTable from './components/ProductTable';
import CustomerTable from './components/CustomerTable';
import CustomerForecast from './components/CustomerForecast';
import ForecastOutputsSummary from './components/ForecastOutputsSummary';
import ForecastOutputsDemand from './components/ForecastOutputsDemand';
import ForecastOutputsAccount from './components/ForecastOutputsAccount';
import ProfilePage from './components/ProfilePage';
import ThirdPartyIntegrationsPage from './components/ThirdPartyIntegrationsPage';
import AdminPage from './components/AdminPage';
import ProtectedRoute from './ProtectedRoute';
import {AuthProvider, useAuth} from './AuthContext';
import Typography from '@mui/material/Typography';
import axiosInstance from "./AxiosInstance";
import {CardGiftcard, Layers, Person2} from "@mui/icons-material";
import Box from "@mui/material/Box";

const NAVIGATION = [
    {
        kind: 'header',
        title: 'Main items',
    },
    {
        segment: '',
        title: 'Dashboard',
        icon: <DashboardIcon/>,
    },
    {
        segment: 'products-table',
        title: 'Products',
        icon: <CardGiftcard/>,
    },
    {
        segment: 'customers-table',
        title: 'Customers',
        icon: <PeopleIcon/>,
    },
    {
        segment: 'customer-forecast',
        title: 'Forecasts',
        icon: <TimelineIcon/>,
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Analytics',
    },
    {
        segment: 'outputs',
        title: 'Outputs',
        icon: <BarChartIcon/>,
        children: [
            {
                segment: 'forecast-outputs',
                title: 'Summary',
                icon: <TableChartIcon/>,
            },
            {
                segment: 'forecast-outputs-account',
                title: 'Account',
                icon: <TableChartIcon/>,
            },
            {
                segment: 'forecast-outputs-demand',
                title: 'Demand',
                icon: <TableChartIcon/>,
            },
        ],
    },
    {
        kind: 'divider',
    },
    {
        kind: 'header',
        title: 'Options',
    },
    {
        segment: 'settings',
        title: 'Settings',
        icon: <SettingsIcon/>,
        children: [
            {
                segment: 'profile',
                title: 'Profile',
                icon: <Person2/>,
            },
            // {  *** Include this when we're ready for Unleashed ***
            //     segment: 'integrations',
            //     title: 'Integrations',
            //     icon: <Layers/>,
            // },
        ]
    },
];

const theme = extendTheme({
    colorSchemes: {light: true, dark: true},
    colorSchemeSelector: 'class',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

function useCustomRouter() {
    const navigate = useNavigate();
    const location = useLocation();

    return React.useMemo(() => ({
        pathname: location.pathname,
        searchParams: new URLSearchParams(location.search),
        navigate: (path) => navigate(path),
    }), [location, navigate]);
}

function SidebarFooter({mini}) {
    const [versionInfo, setVersionInfo] = useState({version: '', buildNumber: ''});

    useEffect(() => {
        axiosInstance.get('/info')
            .then(response => {
                setVersionInfo({
                    version: response.data.version,
                    buildNumber: response.data.buildNumber,
                });
            })
            .catch(error => {
                console.error('Error fetching version info:', error);
            });
    }, []);

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                textAlign: 'center',
            }}
        >
            <Typography
                variant="caption"
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
                {mini
                    ? '© AIQ'
                    : `© ${new Date().getFullYear()} AbakusIQ Ltd | v${versionInfo.version}.${versionInfo.buildNumber}`}
            </Typography>
        </Box>
    );
}

function AppWithNavigation() {

    const router = useCustomRouter();
    const navigate = useNavigate();
    const {isAuthenticated, handleLogout, organisation, email} = useAuth();

    const [session, setSession] = React.useState({
        user: {
            name: organisation || '',
            email: email || '',
        },
    });

    useEffect(() => {
        if (organisation && email) {
            setSession({
                user: {
                    name: organisation,
                    email: email,
                },
            });
        }
    }, [organisation, email]);

    const authentication = React.useMemo(() => {
        return {
            signIn: () => {},
            signOut: () => {
                handleLogout();
                setSession(null);
                navigate('/login');
            },
        };
    }, [handleLogout, navigate]);

    return (
        <AppProvider
            session={session}
            router={router}
            authentication={authentication}
            navigation={NAVIGATION}
            branding={{title: 'AbakusIQ'}}
            theme={theme}
        >
            <DashboardLayout
                hideNavigation={!isAuthenticated}
                slots={{
                    sidebarFooter: SidebarFooter,
                }}
            >
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>

                    {/* Protected Routes */}
                    <Route element={<ProtectedRoute/>}>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/products-table" element={<ProductTable/>}/>
                        <Route path="/customers-table" element={<CustomerTable/>}/>
                        <Route path="/customer-forecast/:id?" element={<CustomerForecast/>}/>
                        <Route path="/outputs/forecast-outputs" element={<ForecastOutputsSummary/>}/>
                        <Route path="/outputs/forecast-outputs-account" element={<ForecastOutputsAccount/>}/>
                        <Route path="/outputs/forecast-outputs-demand" element={<ForecastOutputsDemand/>}/>
                        <Route path="/settings/profile" element={<ProfilePage/>}/>
                        <Route path="/settings/integrations" element={<ThirdPartyIntegrationsPage/>}/>
                    </Route>
                    {/* Admin Routes */}
                    <Route element={<ProtectedRoute isAdminRequired={true}/>}>
                        <Route path="/admin" element={<AdminPage/>}/>
                        <Route path="/register" element={<RegisterPage/>}/>
                    </Route>
                </Routes>
            </DashboardLayout>
        </AppProvider>
    );
}

function App() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="*" element={<AppWithNavigation/>}/>
            </Routes>
        </AuthProvider>
    );
}

export default App;
