import React, { useState, useEffect } from "react";
import OutputsSummaryTable from "./OutputsSummaryTable";
import { FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import ForecastDropdown from "./ForecastDropdown";
import ForecastPeriodDropdown from "./ForecastPeriodDropdown";
import Box from "@mui/material/Box";
import axiosInstance from "../AxiosInstance";
import ForecastProductMultiSelectDropdown from "./ForecastProductMultiSelectDropdown";

const ForecastOutputsSummary = () => {
    const [forecasts, setForecasts] = useState([]);
    const [view, setView] = useState("units");
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodId, setSelectedForecastPeriodId] = useState(null);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [startPeriod, setStartPeriod] = useState(1);
    const [summaryData, setSummaryData] = useState({ channels: [] });
    const [loadingForecasts, setLoadingForecasts] = useState(true);
    const [loadingTable, setLoadingTable] = useState(false);

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem("selectedForecast"));
        const storedForecastPeriodId = localStorage.getItem("selectedForecastPeriodId");

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
            setStartPeriod(storedForecast.startPeriod);
        }

        if (storedForecastPeriodId && storedForecastPeriodId !== "null") {
            setSelectedForecastPeriodId(parseInt(storedForecastPeriodId, 10));
        } else {
            setSelectedForecastPeriodId(null);
        }

        fetchForecastsAndPeriods();
    }, []);

    const fetchForecastsAndPeriods = async () => {
        try {
            setLoadingForecasts(true);
            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setForecasts(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingForecasts(false);
        }
    };

    useEffect(() => {
        if (selectedForecastId && selectedForecastPeriodId !== null) {
            const fetchAccountSummary = async () => {
                setLoadingTable(true);
                try {
                    const params = { forecastPeriodId: selectedForecastPeriodId };
                    if (selectedProductIds !== null && Array.isArray(selectedProductIds)) {
                        params.productIds = selectedProductIds.join(","); // Convert array to comma-separated string
                    }
                    const response = await axiosInstance.get(`/forecasts/${selectedForecastId}/outputs-summary`, {
                        params,
                    });
                    setSummaryData(response.data);
                } catch (error) {
                    console.error("Failed to fetch account summary:", error);
                    setSummaryData({ channels: [] });
                } finally {
                    setLoadingTable(false);
                }
            };

            fetchAccountSummary();
        }
    }, [selectedForecastId, selectedForecastPeriodId, selectedProductIds]);

    const handleForecastSelected = (id, period) => {
        localStorage.setItem("selectedForecast", JSON.stringify({ forecastId: id, startPeriod: period }));
        setSelectedForecastId(id);
        setStartPeriod(period);

        const storedForecastPeriodId = localStorage.getItem("selectedForecastPeriodId");

        if (storedForecastPeriodId) {
            const forecast = forecasts.find((f) => f.forecastId === id);
            const validPeriod = forecast?.forecastPeriods.some(
                (fp) => fp.forecastPeriodId === parseInt(storedForecastPeriodId, 10)
            );
            if (validPeriod) {
                setSelectedForecastPeriodId(storedForecastPeriodId);
            } else {
                setSelectedForecastPeriodId(null);
            }
        } else {
            setSelectedForecastPeriodId(null);
        }
    };

    const handleForecastPeriodSelected = (forecastPeriodId) => {
        localStorage.setItem("selectedForecastPeriodId", forecastPeriodId);
        setSelectedForecastPeriodId(forecastPeriodId);
    };

    const handleProductsSelected = (products) => {
        const productIds = products.map((product) => product.productId);
        setSelectedProductIds(productIds);
    };

    const handleChangeView = (event) => {
        setView(event.target.value);
    };

    const selectedForecast = forecasts.find((f) => f.forecastId === selectedForecastId);

    return (
        <Box className="component-container">
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2, // Spacing between dropdowns
                    pb: 2,
                    flexWrap: "wrap", // Allows wrapping on smaller screens
                }}
            >
                {/* Forecast Dropdown */}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={300} height={40} />
                ) : (
                    <ForecastDropdown
                        forecasts={forecasts}
                        selectedForecastId={selectedForecastId}
                        onForecastSelected={handleForecastSelected}
                        sx={{ width: 300 }}
                    />
                )}
                {/* Forecast Period Dropdown */}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={300} height={40} />
                ) : (
                    <ForecastPeriodDropdown
                        forecastPeriods={selectedForecast ? selectedForecast.forecastPeriods : []}
                        selectedForecastPeriodId={selectedForecastPeriodId}
                        onForecastPeriodSelected={handleForecastPeriodSelected}
                        sx={{ width: 300 }}
                    />
                )}
                {/* SKU Dropdown */}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={300} height={40} />
                ) : (
                    <Box sx={{ flexGrow: 1 }}>
                        <ForecastProductMultiSelectDropdown
                            forecastId={selectedForecastId}
                            customerId={null}
                            forecastPeriodId={selectedForecastPeriodId}
                            onProductsSelected={handleProductsSelected}
                            productErrorText={null}
                            disableCloseOnSelect={true}
                        />
                    </Box>
                )}
                {/* View Selector */}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                    <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                            <Select onChange={handleChangeView} value={view}>
                                <MenuItem value="units">Units</MenuItem>
                                <MenuItem value="grossRev">Gross Revenue</MenuItem>
                                <MenuItem value="netRev">Net Revenue</MenuItem>
                                <MenuItem value="triggers">Triggers</MenuItem>
                                <MenuItem value="fixedSpend">Fixed Spend</MenuItem>
                                <MenuItem value="grossProfit">Gross Profit</MenuItem>
                                <MenuItem value="grossProfitPercent">Gross Profit Percent</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
            </Box>
            {selectedForecastId && selectedForecastPeriodId !== null && selectedForecastPeriodId > 0 && (
                <Box>
                    {loadingTable ? (
                        <Skeleton variant="rectangular" width="100%" height={600} />
                    ) : (
                        <OutputsSummaryTable summaryData={summaryData} view={view} startMonth={startPeriod} />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default ForecastOutputsSummary;
