import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import { Card, Skeleton, Typography } from "@mui/material";
import ForecastDropdown from "./ForecastDropdown";
import ForecastPeriodDropdown from "./ForecastPeriodDropdown";
import axiosInstance from "../AxiosInstance";

export default function Home() {
    const [loadingForecasts, setLoadingForecasts] = useState(true);
    const [forecasts, setForecasts] = useState([]);
    const [selectedForecastId, setSelectedForecastId] = useState(null);
    const [selectedForecastPeriodId, setSelectedForecastPeriodId] = useState(null);
    const [barChartData, setBarChartData] = useState([]); // Default to an empty array
    const [dataset, setDataset] = useState([]); // Default to an empty array
    const [loadingChart, setLoadingChart] = useState(false);

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        const storedForecast = JSON.parse(localStorage.getItem("selectedForecast"));
        const storedForecastPeriodId = localStorage.getItem("selectedForecastPeriodId");

        if (storedForecast?.forecastId) {
            setSelectedForecastId(storedForecast.forecastId);
        }

        if (storedForecastPeriodId) {
            setSelectedForecastPeriodId(storedForecastPeriodId);
        } else {
            setSelectedForecastPeriodId(null);
        }

        fetchForecastsAndPeriods();
    }, []);

    const fetchForecastsAndPeriods = async () => {
        try {
            setLoadingForecasts(true);
            const token = getToken();
            const response = await axiosInstance.get("/forecasts", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setForecasts(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingForecasts(false);
        }
    };

    const handleForecastSelected = (id) => {
        localStorage.setItem("selectedForecast", JSON.stringify({ forecastId: id }));
        setSelectedForecastId(id);
        setSelectedForecastPeriodId(null);
    };

    const handleForecastPeriodSelected = (forecastPeriodId) => {
        if (forecastPeriodId) {
            localStorage.setItem("selectedForecastPeriodId", forecastPeriodId);
            setSelectedForecastPeriodId(forecastPeriodId);
        } else {
            localStorage.removeItem("selectedForecastPeriodId");
            setSelectedForecastPeriodId(null);
        }
    };

    const fetchBarChartData = async () => {
        if (!selectedForecastId || !selectedForecastPeriodId) return;

        try {
            setLoadingChart(true);
            const response = await axiosInstance.get("/dashboard/barchart-data", {
                params: {
                    forecastId: selectedForecastId,
                    forecastPeriodId: selectedForecastPeriodId,
                },
            });

            const startMonth = response.data.startMonth;

            // Generate dynamic month labels
            const months = generateMonthLabels(startMonth);

            // Create dataset with months as the x-axis values
            const newDataset = months.map((month, index) => ({
                label: month,
                ...response.data.series?.reduce((acc, series) => {
                    acc[series.label] = series.data[index] || 0;
                    return acc;
                }, {}),
            }));

            setDataset(newDataset);

            // Safely set series data
            setBarChartData(response.data.series || []);
        } catch (error) {
            console.error("Error fetching bar chart data:", error);
            setBarChartData([]); // Set to an empty array on error
            setDataset([]);
        } finally {
            setLoadingChart(false);
        }
    };

    useEffect(() => {
        fetchBarChartData();
    }, [selectedForecastId, selectedForecastPeriodId]);

    const selectedForecast = forecasts.find((f) => f.forecastId === selectedForecastId);

    // Utility function to generate month labels
    const generateMonthLabels = (startMonth) => {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return months.slice(startMonth - 1).concat(months.slice(0, startMonth - 1));
    };

    return (
        <Box className="component-container">
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, pb: 2 }}>
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                    <ForecastDropdown
                        forecasts={forecasts}
                        selectedForecastId={selectedForecastId}
                        onForecastSelected={handleForecastSelected}
                    />
                )}
                {loadingForecasts ? (
                    <Skeleton variant="rectangular" width={200} height={40} />
                ) : (
                    <ForecastPeriodDropdown
                        forecastPeriods={selectedForecast?.forecastPeriods || []}
                        selectedForecastPeriodId={selectedForecastPeriodId}
                        onForecastPeriodSelected={handleForecastPeriodSelected}
                    />
                )}
            </Box>
            {selectedForecastId && selectedForecastPeriodId && (
                <Card variant="outlined" sx={{ borderRadius: 2, height: "100%", padding: "1rem" }}>
                    <Typography variant="h6" align="left" gutterBottom>
                        Gross Revenue (£)
                    </Typography>
                    {loadingChart ? (
                        <Skeleton variant="rectangular" width="100%" height={300} />
                    ) : (
                        <BarChart
                            height={300}
                            dataset={dataset}
                            xAxis={[
                                {
                                    dataKey: "label",
                                    label: "Months",
                                    scaleType: "band",
                                },
                            ]}
                            series={barChartData?.map((s) => ({
                                dataKey: s.label,
                                label: s.label,
                            })) || []}
                            margin={{ left: 80, right: 20, top: 20, bottom: 20 }}
                        />
                    )}
                </Card>
            )}
        </Box>
    );
}