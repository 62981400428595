import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    DataGrid,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    MenuItem,
    Paper,
    Snackbar,
    Typography,
} from "@mui/material";
import Draggable from "react-draggable";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ForecastProductMultiSelectDropdown from "./ForecastProductMultiSelectDropdown";
import TextField from "@mui/material/TextField";
import convertDateStringToDate, {formatDate, formatDateForInput, parseDate} from "../Utilities/dateUtility";
import {
    isGreaterOrEqualToZero,
    isGreaterThanZero,
    isMoneyGreaterOrEqualToZero,
    isMoneyGreaterThanZero,
    validateString
} from '../Utilities/validation';
import axiosInstance from "../AxiosInstance";
import {FileCopy} from "@mui/icons-material";
import {getChangedWeeklyDistributionRates} from "../Utilities/distributionRateUtils";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function PromoEditToolbar({ title, onAdd, onEdit, onDelete, onClone, selectedPromotion }) {
    return (
        <GridToolbarContainer sx={{ justifyContent: "space-between", width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" sx={{ mr: 2, pl: 1 }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton aria-label="createPromotion" onClick={onAdd}>
                    <AddIcon />
                </IconButton>
                <IconButton aria-label="clone" onClick={onClone} disabled={!selectedPromotion}>
                    <FileCopy />
                </IconButton>
                <IconButton aria-label="edit" onClick={onEdit} disabled={!selectedPromotion}>
                    <EditIcon/>
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() => onDelete(selectedPromotion)}
                    disabled={!selectedPromotion}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
        </GridToolbarContainer>
    );
}

export default function PromotionsTable({
                                            forecastId,
                                            forecastPeriodId,
                                            customerId,
                                            onRefreshSkuBaseForecasts,
                                            onPromotionProductsDataChange
                                        }) {
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedPromotionId, setSelectedPromotionId] = useState(null);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [snackbar, setSnackbar] = useState(null);
    const [features, setFeatures] = useState([]);
    const [promotionTypes, setPromotionTypes] = useState([]);
    const [buyInDateOptions, setBuyInDateOptions] = useState([]);
    const [openPromotionProductDialog, setOpenPromotionProductDialog] = useState(false);
    const [promotionProductFormData, setPromotionProductFormData] = useState({
        recommendedRetailPrice: 0,
        unitFunding: 0,
        skuBaseForecastId: []
    });
    const [openPromotionDialog, setOpenPromotionDialog] = useState(false);
    const [promotionFormData, setPromotionFormData] = useState({
        promotionTypeId: 1,
        name: "",
        startDate: "",
        endDate: "",
        buyInStart: "",
        buyInEnd: "",
        fixedSpend: 0,
        featureId: 1,
        stores: 0,
    });
    const [skuBaseForecastErrorText, setSkuBaseForecastErrorText] = useState('');
    const [recommendedRetailPriceErrorText, setRecommendedRetailPriceErrorText] = useState('');
    const [unitFundingErrorText, setUnitFundingErrorText] = useState('');
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedPromotionProductId, setSelectedPromotionProductId] = useState(null);
    const [selectedPromotionProduct, setSelectedPromotionProduct] = useState(null);
    const [confirmingDeletionType, setConfirmingDeletionType] = useState(null);
    const [editedPromotionProductRows, setEditedPromotionProductRows] = useState({});

    const [promotionTypeIdError, setPromotionTypeIdError] = useState('');
    const [nameError, setNameError] = useState('');
    const [fixedSpendError, setFixedSpendError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [featureIdError, setFeatureIdError] = useState('');
    const [storesError, setStoresError] = useState('');
    const [buyInStartError, setBuyInStartError] = useState('');
    const [buyInEndError, setBuyInEndError] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCloneMode, setIsCloneMode] = useState(false);

    const handleOpenConfirmationDialog = (type) => {
        setConfirmingDeletionType(type);
        setOpenConfirmationDialog(true);
    };

    const handleCloseConfirmationDialog = () => {
        setConfirmingDeletionType(null);
        setOpenConfirmationDialog(false);
    };

    const handleConfirmDeletePromotion = async () => {
        try {
            const response = await axiosInstance.delete(`/forecasts/${forecastId}/promotions/${selectedPromotion.id}?forecastPeriodId=${forecastPeriodId}`);

            if (response.status === 204 || response.status === 200) {
                setRows(rows.filter(row => row.id !== selectedPromotion.id));
            }

            if (response.status === 204) {
                setSnackbar({children: "Promotion successfully deleted", severity: "success"});
            } else if (response.status === 200) {
                setSnackbar({children: "Promotion successfully deactivated", severity: "success"});
            }
        } catch (error) {
            console.error(`Error deleting the promotion: ${error}`);
            setSnackbar({
                children: `Error deleting the promotion: ${error.response?.data || error.message}`,
                severity: "error"
            });
        } finally {
            setSelectedPromotion(null);
            setSelectedPromotionId(null);
        }
        handleCloseConfirmationDialog();
    };

    const handleConfirmDeletePromotionProduct = async () => {
        if (!selectedPromotionProduct) {
            setSnackbar({children: "No promotion product selected", severity: "error"});
            return;
        }

        try {
            const response = await axiosInstance.delete(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts/${selectedPromotionProductId}?forecastPeriodId=${forecastPeriodId}`);

            if (response.status === 204 || response.status === 200) {
                const updatedPromotionProducts = selectedPromotion.promotionProducts.filter(pp => pp.promotionProductId !== selectedPromotionProductId);
                setSelectedPromotion({...selectedPromotion, promotionProducts: updatedPromotionProducts});
            }

            if (response.status === 204) {
                setSnackbar({children: "Promotion product successfully deleted", severity: "success"});
            } else if (response.status === 200) {
                setSnackbar({children: "Promotion product successfully deactivated", severity: "success"});
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    setSnackbar({
                        children: `Conflict: ${error.response.data.message}`,
                        severity: "warning"
                    });
                } else {
                    setSnackbar({
                        children: `Error deleting the promotion product: ${error.response.data || error.message}`,
                        severity: "error"
                    });
                }
            } else {
                setSnackbar({
                    children: `Error deleting the promotion product: ${error.message}`,
                    severity: "error"
                });
            }
        } finally {
            setSelectedPromotionProduct(null);
            setSelectedPromotionProductId(null);
            handleCloseConfirmationDialog();
        }
    };


    useEffect(() => {
        getPromotions();
    }, [forecastId, forecastPeriodId, customerId]);

    useEffect(() => {
        getBuyInDateOptions();
    }, [forecastId]);

    useEffect(() => {
        if (selectedPromotionId) {
            const updatedSelectedPromotion = rows.find(p => p.id === selectedPromotionId);
            setSelectedPromotion(updatedSelectedPromotion);
        }
    }, [rows, selectedPromotionId]);

    useEffect(() => {
        const fetchFeatures = async () => {
            try {
                const response = await axiosInstance.get('/features');
                setFeatures(response.data);
            } catch (error) {
                console.error('Error fetching features:', error);
            }
        };

        fetchFeatures();
    }, []);

    useEffect(() => {
        const fetchPromoTypes = async () => {
            try {
                const response = await axiosInstance.get('/promotionTypes');
                setPromotionTypes(response.data);
            } catch (error) {
                console.error('Error fetching promotion types:', error);
            }
        };

        fetchPromoTypes();
    }, []);

    const getPromotions = async () => {
        try {
            const response = await axiosInstance.get(`/forecasts/${forecastId}/promotions/${customerId}?forecastPeriodId=${forecastPeriodId}`);
            const promotions = response.data || [];
            setRows(promotions);
        } catch (error) {
            console.log(error);
        }
    };

    const getBuyInDateOptions = async () => {
        try {
            const response = await axiosInstance.get(`/forecasts/${forecastId}/promotions/buyInDateOptions`);
            const buyInDateOptions = response.data;
            setBuyInDateOptions(buyInDateOptions);
        } catch (error) {
            console.log(error);
        }
    }

    useCallback((error) => {
        setSnackbar({children: error.message, severity: 'error'});
    }, []);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handlePromotionRowClick = (params) => {
        if (params.row.isNew) {
            setSelectedPromotion(null);
            setSelectedPromotionId(null);
            return;
        }

        const promotionProductsData = params.row.promotionProducts.map(product => ({
            skuBaseForecastId: product.skuBaseForecastId,
            weeklyDistributionRates: product.weeklyDistributionRates.map(rate => rate.week)
        }));

        onPromotionProductsDataChange(promotionProductsData);

        setSelectedPromotionId(params.id);
        setSelectedPromotion(params.row);
    };

    const handleCreatePromotionDialog = () => {
        setSelectedPromotion(null);
        setIsEditMode(false);
        setPromotionFormData({
            promotionTypeId: 1,
            name: "",
            startDate: "",
            endDate: "",
            buyInStart: "",
            buyInEnd: "",
            fixedSpend: 0,
            featureId: 1,
            stores: 0,
        });
        setPromotionTypeIdError('');
        setNameError('');
        setFixedSpendError('');
        setStartDateError('');
        setEndDateError('');
        setFeatureIdError('');
        setStoresError('');
        setBuyInStartError('');
        setBuyInEndError('');
        setOpenPromotionDialog(true);
    };

    const handleCloseDialogs = () => {
        setOpenPromotionDialog(false);
        setOpenPromotionProductDialog(false);
    };

    const handleUpsertPromotion = async (data) => {
        const isEditMode = !!selectedPromotion && !isCloneMode;
        
        const isValidPromotionTypeId = isGreaterThanZero(data.promotionTypeId);
        const isValidName = validateString(data.name);
        const isValidFixedSpend = isMoneyGreaterOrEqualToZero(data.fixedSpend);

        const requiresExtendedValidation = data.promotionTypeId !== 2;

        let isValidStartDate = true;
        let isValidEndDate = true;
        let isValidDatesOrder = true;

        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);
        if (requiresExtendedValidation) {
            isValidStartDate = !isNaN(startDate.getTime());
            isValidEndDate = !isNaN(endDate.getTime());
            isValidDatesOrder = endDate >= startDate;
        } else {
            data.startDate = null;
            data.endDate = null;
            data.featureId = null;
            data.stores = null;
        }
        
        const buyInStart = parseDate(data.buyInStart);
        const buyInEnd = parseDate(data.buyInEnd);

        const isValidBuyInStart = Boolean(buyInStart);
        const isValidBuyInEnd = Boolean(buyInEnd);
        
        const isBuyInDatesOrderCorrect = buyInEnd >= buyInStart;
        
        const isValidFeatureId = !requiresExtendedValidation || isGreaterThanZero(data.featureId);
        const isValidStores = !requiresExtendedValidation || isGreaterOrEqualToZero(data.stores);

        setPromotionTypeIdError(isValidPromotionTypeId ? "" : "Please select a valid promotion type");
        setNameError(isValidName ? "" : "Please enter a valid value for Promotion Name");
        setFixedSpendError(isValidFixedSpend ? "" : "Please enter a valid value for Fixed Spend");

        if (requiresExtendedValidation) {
            setStartDateError(isValidStartDate ? "" : "Please enter a valid start date");
            if (!isValidEndDate) {
                setEndDateError("Please enter a valid end date");
            } else if (!isValidDatesOrder) {
                setEndDateError("End date must be on or after start date");
            } else {
                setEndDateError("");
            }
            setFeatureIdError(isValidFeatureId ? "" : "Please select a valid feature");
            setStoresError(isValidStores ? "" : "Please enter a valid number of stores");
        }
        setBuyInStartError(isValidBuyInStart ? "" : "Please select a valid Buy In Start date");
        
        if (!isValidBuyInEnd) {
            setBuyInEndError("Please select a valid Buy In End date");
        } else if (!isBuyInDatesOrderCorrect) {
            setBuyInEndError("Buy In End date must be on or after Buy In Start date");
        } else {
            setBuyInEndError("");
        }

        if (isCloneMode && requiresExtendedValidation) {
            const clonedStart = parseDate(selectedPromotion.buyInStart);
            const clonedEnd = parseDate(selectedPromotion.buyInEnd);
            const extendedClonedEnd = new Date(clonedEnd);
            extendedClonedEnd.setDate(clonedEnd.getDate() + 6);

            const isBuyInOverlapping =
                (buyInStart >= clonedStart && buyInStart <= extendedClonedEnd) ||
                (buyInEnd >= clonedStart && buyInEnd <= extendedClonedEnd);

            if (isBuyInOverlapping) {
                setBuyInStartError("Buy In Start overlaps with the cloned promotion");
                setBuyInEndError("Buy In End overlaps with the cloned promotion");
            }

            const clonedStartDate = new Date(selectedPromotion.startDate);
            const clonedEndDate = new Date(selectedPromotion.endDate);

            const isStartEndOverlapping =
                startDate &&
                endDate &&
                clonedStartDate &&
                clonedEndDate &&
                (
                    (startDate >= clonedStartDate && startDate <= clonedEndDate) || // Start date overlaps
                    (endDate >= clonedStartDate && endDate <= clonedEndDate) || // End date overlaps
                    (startDate <= clonedStartDate && endDate >= clonedEndDate) // Completely contains the cloned range
                );

            if (isStartEndOverlapping) {
                setStartDateError("Start date overlaps with the cloned promotion");
                setEndDateError("End date overlaps with the cloned promotion");
            }

            if (isBuyInOverlapping || isStartEndOverlapping) {
                return;
            }
        }

        if (
            !isValidPromotionTypeId ||
            !isValidName ||
            !isValidFixedSpend ||
            (requiresExtendedValidation &&
                (!isValidStartDate || !isValidEndDate || !isValidDatesOrder || !isValidFeatureId || !isValidStores)) ||
            !isValidBuyInStart ||
            !isValidBuyInEnd ||
            !isBuyInDatesOrderCorrect
        ) {
            return;
        }

        setPromotionTypeIdError("");
        setNameError("");
        setFixedSpendError("");
        if (requiresExtendedValidation) {
            setStartDateError("");
            setEndDateError("");
            setFeatureIdError("");
            setStoresError("");
        }
        setBuyInStartError("");
        setBuyInEndError("");

        data.customerId = customerId;
        data.forecastPeriodId = forecastPeriodId;

        try {
            if (isCloneMode) {
                const response = await axiosInstance.post(`/forecasts/${forecastId}/promotions/clone/${selectedPromotion.id}`, data);
                const clonedPromotion = response.data;
                setRows((prevRows) => [...prevRows, clonedPromotion]);
                setSelectedPromotionId(clonedPromotion.id);
                setSelectedPromotion(clonedPromotion);
                setSnackbar({ children: "Promotion successfully cloned", severity: "success" });
            } else if (isEditMode) {
                const updatedData = { ...data, id: selectedPromotion.id };
                await axiosInstance.put(`/forecasts/${forecastId}/promotions`, updatedData);
                setSnackbar({ children: 'Promotion successfully updated', severity: 'success' });
            } else {
                const response = await axiosInstance.post(`/forecasts/${forecastId}/promotions`, data);
                setRows(prevRows => [...prevRows, response.data]);
                setSnackbar({ children: 'Promotion successfully created', severity: 'success' });
            }

            await getPromotions();
            handleCloseDialogs();
            onRefreshSkuBaseForecasts();
        } catch (error) {
            const errorMessage = error.response?.data || "Error creating/updating promotion.";
            setSnackbar({ children: errorMessage, severity: "error" });
        }
    };

    const handleRowSelection = (newSelectionModel) => {
        const newSelectedId = newSelectionModel[0] || null;
        setSelectedPromotionId(newSelectedId);
    };

    const handleDeleteClick = () => {
        if (!selectedPromotion || !selectedPromotion.id) {
            console.error("No promotion selected for deletion.");
            setSnackbar({children: "No promotion selected", severity: "error"});
            return;
        }
        handleOpenConfirmationDialog('promotion');
    };
    
    const handleEditPromotionDialog = async () => {
        if (!selectedPromotion) return;

        setIsEditMode(true);
        setIsCloneMode(false);
        setPromotionFormData({
            promotionTypeId: selectedPromotion.promotionTypeId,
            name: selectedPromotion.name,
            startDate: formatDateForInput(selectedPromotion.startDate),
            endDate: formatDateForInput(selectedPromotion.endDate),
            buyInStart: selectedPromotion.buyInStart,
            buyInEnd: selectedPromotion.buyInEnd,
            fixedSpend: selectedPromotion.fixedSpend,
            featureId: selectedPromotion.featureId,
            stores: selectedPromotion.stores,
        });

        setOpenPromotionDialog(true);
    }
    
    const handleClonePromotionDialog = async () => {
        if (!selectedPromotion) return;

        setIsEditMode(false);
        setIsCloneMode(true);
        setPromotionFormData({
            promotionTypeId: selectedPromotion.promotionTypeId,
            name: `${selectedPromotion.name} - Clone`,
            startDate: formatDateForInput(selectedPromotion.startDate),
            endDate: formatDateForInput(selectedPromotion.endDate),
            buyInStart: selectedPromotion.buyInStart,
            buyInEnd: selectedPromotion.buyInEnd,
            fixedSpend: selectedPromotion.fixedSpend,
            featureId: selectedPromotion.featureId,
            stores: selectedPromotion.stores,
        });

        setOpenPromotionDialog(true);
    }
    
    const columns = [
        { field: "id", headerName: "ID", width: 50 },
        {
            field: "promotionTypeId",
            headerName: "Type",
            width: 120,
            valueGetter: (value, row) => {
                const promotionType = promotionTypes.find(
                    (type) => type.id === value
                );
                return promotionType ? promotionType.name : "";
            },
        },
        { field: "name", headerName: "Name", width: 150 },
        {
            field: 'startDate',
            headerName: 'Promo Start',
            type: 'date',
            width: 100,
            valueFormatter: (value) => formatDate(value),
        },
        {
            field: 'endDate',
            headerName: 'Promo End',
            type: 'date',
            width: 100,
            valueFormatter: (value) => formatDate(value),
        },
        {
            field: "buyInStart",
            headerName: "Buy In Start",
            width: 100,
        },
        {
            field: "buyInEnd",
            headerName: "Buy In End",
            width: 100,
        },
        {
            field: "fixedSpend",
            headerName: "Fixed Spend",
            width: 100,
            valueFormatter: (value) => {
                return '£' + parseFloat(value).toFixed(2);
            },
        },
        {
            field: "featureId",
            headerName: "Feature",
            width: 120,
            valueFormatter: (value) => {
                if (value === null) return '';  // Return empty if value is null
                const feature = features.find(f => f.featureId === value);
                return feature ? feature.name : '';
            },
        },
        {
            field: "stores",
            headerName: "Stores",
            type: "number",
            width: 80,
            valueFormatter: (value) => value !== null ? value : '',  // Display nothing if stores is null
        },
        {
            field: 'totalUnits',
            headerName: 'Total Units',
            type: 'number',
            width: 80,
        },
        {
            field: 'netRevenue',
            headerName: 'Net Rev',
            type: 'number',
            width: 100,
            valueFormatter: (value) => value !== null && value !== undefined ? `£${value.toFixed(2)}` : '',
        },
    ];

    const promotionProductColumns = useMemo(() => {
        if (!selectedPromotion || !selectedPromotion.promotionProducts) {
            return [];
        }

        const buyInEndDate = convertDateStringToDate(selectedPromotion?.buyInEnd);

        if (buyInEndDate) {
            buyInEndDate.setHours(0, 0, 0, 0);
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isReadOnly = buyInEndDate ? today > buyInEndDate : false;

        const staticColumns = [
            {field: 'promotionProductId', headerName: 'ID', width: 100},
            {field: 'skuBaseForecastId', headerName: 'SBF ID', width: 100},
            {field: 'skuDescription', headerName: 'SKU Description', width: 200},
            {
                field: 'recommendedRetailPrice',
                headerName: 'RRP',
                type: 'number',
                width: 100,
                editable: !isReadOnly,
                valueFormatter: (value) => value !== null && value !== undefined ? `£${parseFloat(value).toFixed(2)}` : '£0.00',
                cellClassName: (params) => isReadOnly ? 'nonEditableCell' : '',
            },
            {
                field: 'unitFunding',
                headerName: selectedPromotion.promotionTypeId === 3 ? "Promotional Cost Price" : "Unit Funding",
                type: 'number',
                width: 100,
                editable: !isReadOnly,
                valueFormatter: (value) => value !== null && value !== undefined ? `£${parseFloat(value).toFixed(2)}` : '£0.00',
                cellClassName: (params) => isReadOnly ? 'nonEditableCell' : '',
            }
        ];

        let promotionProducts = selectedPromotion?.promotionProducts;

        const dynamicColumns = promotionProducts.length && promotionProducts[0]?.weeklyDistributionRates
            ? promotionProducts[0].weeklyDistributionRates.map(wdr => {
                const [day, month, year] = wdr.week.split('-').map(Number);
                const columnDate = new Date(year, month - 1, day);
                columnDate.setHours(0, 0, 0, 0);

                let isEditable = columnDate >= today;

                return {
                    field: wdr.week,
                    headerName: wdr.week,
                    type: 'number',
                    width: 100,
                    editable: isEditable,
                    valueGetter: (value, row) => row.weeklyDistributionRates.find(w => w.week === wdr.week)?.actualDistributionRate,
                    cellClassName: (params) => isEditable ? '' : 'nonEditableCell',
                };
            })
            : [];

        return [...staticColumns, ...dynamicColumns];
    }, [selectedPromotion]);

    const handleCreatePromotionProductClick = () => {
        setOpenPromotionProductDialog(true);
        setPromotionProductFormData({
            recommendedRetailPrice: 0,
            unitFunding: 0,
            skuBaseForecastId: []
        });
        setSkuBaseForecastErrorText('');
        setRecommendedRetailPriceErrorText('');
        setUnitFundingErrorText('');
    };

    const handleClose = () => {
        setOpenPromotionProductDialog(false);
    };

    const handleProductsSelected = (products) => {
        setPromotionProductFormData((prevState) => ({
            ...prevState,
            skuBaseForecastId: products.map(product => product.skuBaseForecastId),
        }));
    };

    const handleTextFieldChange = (event) => {
        const {name, value} = event.target;

        setPromotionProductFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreatePromoProduct = async (data) => {
        const isValidRecommendedRetailPrice = isMoneyGreaterThanZero(data.recommendedRetailPrice);
        const isValidUnitFunding = isMoneyGreaterThanZero(data.unitFunding);

        const isValidSkuBaseForecastId = data.skuBaseForecastId.length > 0;

        if (!isValidRecommendedRetailPrice || !isValidUnitFunding || !isValidSkuBaseForecastId) {
            setRecommendedRetailPriceErrorText(isValidRecommendedRetailPrice ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setUnitFundingErrorText(isValidUnitFunding ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setSkuBaseForecastErrorText(isValidSkuBaseForecastId ? '' : 'Please select a SKU');
            return;
        }

        setRecommendedRetailPriceErrorText('');
        setUnitFundingErrorText('');
        setSkuBaseForecastErrorText('');

        try {
            data.customerId = customerId;
            data.forecastPeriodId = forecastPeriodId;

            const requests = data.skuBaseForecastId.map(async (skuBaseForecastId) => {
                const promotionData = { ...data, skuBaseForecastId };
                try {
                    await axiosInstance.post(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts`, promotionData);
                    return { skuBaseForecastId, status: 'success' };
                } catch (error) {
                    const errorMessage = error.response?.data || error.message || 'Unknown error';
                    return { skuBaseForecastId, status: 'failed', errorMessage };
                }
            });

            const results = await Promise.all(requests);

            const successful = results.filter(r => r.status === 'success').map(r => r.skuBaseForecastId);
            const failed = results.filter(r => r.status === 'failed');

            if (successful.length > 0 && failed.length === 0) {
                setSnackbar({
                    children: 'Promotion products created successfully.',
                    severity: 'success',
                });
            } else if (failed.length > 0 && successful.length === 0) {
                const errorMessages = failed
                    .map(f => `SKU Base Forecast ID: ${f.skuBaseForecastId} - ${f.errorMessage}`)
                    .join('\n');
                setSnackbar({
                    children: `Failed to create promotion products:\n${errorMessages}`,
                    severity: 'error',
                });
            } else {
                const errorMessages = failed
                    .map(f => `SKU Base Forecast ID: ${f.skuBaseForecastId} - ${f.errorMessage}`)
                    .join('\n');

                setSnackbar({
                    children: `Failed to create the following promotion products:\n${errorMessages}`,
                    severity: 'error',
                });
            }

            // Close the modal if at least one was successful
            if (successful.length > 0) {
                handleClose();
                await getPromotions();
            }
        } catch (error) {
            console.error('Error creating promotion products:', error);
            setSnackbar({
                children: 'Unexpected error while processing promotion products.',
                severity: 'error',
            });
        }
    };

    const handlePromotionProductRowClick = (params) => {
        setSelectedPromotionProductId(params.id);
        setSelectedPromotionProduct(params.row);
    };

    function handleProcessPromotionProductRowUpdateError(error) {
        setSnackbar({children: "There was an error processing the row update. Please try again.", severity: "error"});
    }

    function moveTopLevelDateKeysToNested(newRow) {
        const weeklyRatesMap = newRow.weeklyDistributionRates.reduce((acc, current) => {
            acc[current.week] = current;
            return acc;
        }, {});

        const datePattern = /^\d{2}-\d{2}-\d{4}$/;

        Object.keys(newRow).forEach(key => {
            if (datePattern.test(key) && weeklyRatesMap[key]) {
                weeklyRatesMap[key].actualDistributionRate = newRow[key];
                delete newRow[key];
            }
        });

        newRow.weeklyDistributionRates = Object.values(weeklyRatesMap);

        return newRow;
    }

    const isValidWeeklyDistributionRates = (weeklyDistributionRates) => {
        return weeklyDistributionRates.every(rateObj => {
            const intValue = parseInt(rateObj.actualDistributionRate, 10);
            return !isNaN(intValue) && intValue >= 0 && intValue === parseFloat(rateObj.actualDistributionRate);
        });
    };

    const processPromotionProductRowUpdates = (newRow, oldRow) => {
        let validationErrors = [];
        let changes = {};

        const copiedNewRow = JSON.parse(JSON.stringify(newRow));
        moveTopLevelDateKeysToNested(copiedNewRow);

        const changedRates = getChangedWeeklyDistributionRates(copiedNewRow.weeklyDistributionRates, oldRow.weeklyDistributionRates);

        if (!isValidWeeklyDistributionRates(changedRates)) {
            validationErrors.push("Please enter a valid value for weekly distribution rate.");
            copiedNewRow.weeklyDistributionRates = oldRow.weeklyDistributionRates;
        } else if (changedRates.length > 0) {
            changes.weeklyDistributionRates = changedRates;
        }

        if (!isMoneyGreaterOrEqualToZero(newRow.unitFunding)) {
            validationErrors.push("Please enter a valid value for unit funding.");
            copiedNewRow.unitFunding = oldRow.unitFunding;
        } else if (newRow.unitFunding !== oldRow.unitFunding) {
            changes.unitFunding = newRow.unitFunding;
        }

        if (!isMoneyGreaterOrEqualToZero(newRow.recommendedRetailPrice)) {
            validationErrors.push("Please enter a valid value for RRP.");
            copiedNewRow.recommendedRetailPrice = oldRow.recommendedRetailPrice;
        } else if (newRow.recommendedRetailPrice !== oldRow.recommendedRetailPrice) {
            changes.recommendedRetailPrice = newRow.recommendedRetailPrice;
        }

        if (validationErrors.length > 0) {
            setSnackbar({children: validationErrors.join(" "), severity: "error"});
            setEditedPromotionProductRows(prevState => {
                const newState = {...prevState};
                delete newState[copiedNewRow.promotionProductId];
                return newState;
            });
            return oldRow;
        } else {
            if (Object.keys(changes).length > 0) {
                setEditedPromotionProductRows(prevState => ({
                    ...prevState,
                    [copiedNewRow.promotionProductId]: {...oldRow, ...changes}
                }));
            }
            return copiedNewRow;
        }
    }

    const transformEditedRowToOverrideRequest = (editedRow, originalRow, forecastPeriodId) => {
        const promotionDistributionRateOverrides = getChangedWeeklyDistributionRates(
            editedRow.weeklyDistributionRates,
            originalRow.weeklyDistributionRates
        ).map(change => ({
            PromotionProductId: editedRow.promotionProductId,
            WeekStart: change.week,
            PromoDistributionRate: change.actualDistributionRate,
            ForecastPeriodId: forecastPeriodId
        }));

        let promotionProductOverrides = null;
        if (editedRow.unitFunding !== originalRow.unitFunding || editedRow.recommendedRetailPrice !== originalRow.recommendedRetailPrice) {
            promotionProductOverrides = {
                PromotionProductId: editedRow.promotionProductId,
                UnitFunding: parseFloat(editedRow.unitFunding || 0),
                RecommendedRetailPrice: parseFloat(editedRow.recommendedRetailPrice || 0),
                ForecastPeriodId: forecastPeriodId
            };
        }

        return {
            PromotionDistributionRateOverrides: promotionDistributionRateOverrides,
            PromotionProductOverrides: promotionProductOverrides
        };
    };


    const handleSavePromotionProductClick = async () => {
        try {
            const rowsArray = Object.values(editedPromotionProductRows);

            const transformedRows = rowsArray.map(editedRow => {
                const originalRow = selectedPromotion.promotionProducts.find(row => row.promotionProductId === editedRow.promotionProductId);
                return transformEditedRowToOverrideRequest(editedRow, originalRow, forecastPeriodId);
            });

            const payload = {
                PromotionDistributionRateOverrides: [].concat(...transformedRows.map(row => row.PromotionDistributionRateOverrides)),
                PromotionProductOverrides: transformedRows.map(row => row.PromotionProductOverrides).filter(override => override !== null),
                forecastPeriodId: forecastPeriodId
            };

            const response = await axiosInstance.post(`/forecasts/${forecastId}/promotions/${selectedPromotionId}/promotionProducts/bulk`, payload);

            if (response.status === 200) {
                setSnackbar({children: "Changes saved successfully", severity: "success"});
                setEditedPromotionProductRows({});
                await getPromotions();
                onRefreshSkuBaseForecasts();
            } else {
                setSnackbar({children: "Failed to save changes", severity: "error"});
            }
        } catch (error) {
            console.error(error.response.data);
            setSnackbar({children: `Error saving changes: ${error.response.data}`, severity: "error"});
        }
    }

    const PromoProductEditToolbar = ({title}) => {
        const handleDeletePromotionProduct = () => {
            if (!selectedPromotionProduct) {
                setSnackbar({children: "No promotion product selected", severity: "error"});
                return;
            }
            handleOpenConfirmationDialog('promotionProduct');
        };

        return (
            <GridToolbarContainer sx={{justifyContent: 'space-between', width: '100%'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h6" sx={{mr: 2, pl: 1}}>
                        {title}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton aria-label="createPromotionProduct" onClick={handleCreatePromotionProductClick}>
                        <AddIcon/>
                    </IconButton>
                    <IconButton aria-label="save" onClick={handleSavePromotionProductClick}
                                disabled={Object.keys(editedPromotionProductRows).length === 0}>
                        <SaveIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={handleDeletePromotionProduct}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                rowSelectionModel={selectedPromotionId ? [selectedPromotionId] : []}
                onRowSelectionModelChange={handleRowSelection}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                editMode="row"
                rowModesModel={rowModesModel}
                slots={{
                    toolbar: PromoEditToolbar,
                }}
                slotProps={{
                    toolbar: {
                        title: "Promotions",
                        onAdd: handleCreatePromotionDialog,
                        onEdit: handleEditPromotionDialog,
                        onDelete: handleDeleteClick,
                        onClone: handleClonePromotionDialog,
                        selectedPromotion,
                    },
                }}
                onRowClick={handlePromotionRowClick}
                pageSize={10}
                autoHeight
            />

            <Dialog open={openPromotionDialog} onClose={handleCloseDialogs} PaperComponent={PaperComponent}>
                <DialogTitle>Create New Promotion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isEditMode ? "Update the details for the existing promotion." : "Enter the details for the new promotion."}
                    </DialogContentText>

                    {/* 1. Promotion Type Dropdown */}
                    <TextField
                        select
                        label="Promotion Type"
                        name="promotionTypeId"
                        fullWidth
                        value={promotionFormData.promotionTypeId || ''}
                        onChange={(e) => setPromotionFormData({ ...promotionFormData, promotionTypeId: parseInt(e.target.value) })}
                        sx={{ mt: 2 }}
                        error={Boolean(promotionTypeIdError)}
                        helperText={promotionTypeIdError}
                        disabled={isEditMode}
                    >
                        {promotionTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/* 2. Name Text Field (Already Done) */}
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        value={promotionFormData.name}
                        onChange={(e) => setPromotionFormData({ ...promotionFormData, name: e.target.value })}
                        sx={{ mt: 2 }}
                        error={Boolean(nameError)}
                        helperText={nameError}
                    />

                    {/* 3. Buy In Start Dropdown */}
                    <TextField
                        select
                        label="Buy In Start"
                        name="buyInStart"
                        fullWidth
                        value={promotionFormData.buyInStart || ''}
                        onChange={(e) => setPromotionFormData({ ...promotionFormData, buyInStart: e.target.value })}
                        sx={{ mt: 2 }}
                        error={Boolean(buyInStartError)}
                        helperText={buyInStartError}
                    >
                        {buyInDateOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/* 4. Buy In End Dropdown */}
                    <TextField
                        select
                        label="Buy In End"
                        name="buyInEnd"
                        fullWidth
                        value={promotionFormData.buyInEnd || ''}
                        onChange={(e) => setPromotionFormData({ ...promotionFormData, buyInEnd: e.target.value })}
                        sx={{ mt: 2 }}
                        error={Boolean(buyInEndError)}
                        helperText={buyInEndError}
                    >
                        {buyInDateOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/* 5. Fixed Spend Number Input */}
                    <TextField
                        label="Fixed Spend"
                        name="fixedSpend"
                        type="number"
                        fullWidth
                        value={promotionFormData.fixedSpend}
                        onChange={(e) => setPromotionFormData({ ...promotionFormData, fixedSpend: parseFloat(e.target.value) })}
                        sx={{ mt: 2 }}
                        error={Boolean(fixedSpendError)}
                        helperText={fixedSpendError}
                    />

                    {/* Conditional Fields for promotionTypeId !== 2 */}
                    {promotionFormData.promotionTypeId !== 2 && (
                        <>
                            {/* 6. Promo Start Date Picker */}
                            <TextField
                                label="Promo Start"
                                name="startDate"
                                type="date"
                                fullWidth
                                value={promotionFormData.startDate || ''}
                                onChange={(e) => setPromotionFormData({ ...promotionFormData, startDate: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                sx={{ mt: 2 }}
                                error={Boolean(startDateError)}
                                helperText={startDateError}
                            />

                            {/* 7. Promo End Date Picker */}
                            <TextField
                                label="Promo End"
                                name="endDate"
                                type="date"
                                fullWidth
                                value={promotionFormData.endDate || ''}
                                onChange={(e) => setPromotionFormData({ ...promotionFormData, endDate: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                sx={{ mt: 2 }}
                                error={Boolean(endDateError)}
                                helperText={endDateError}
                            />

                            {/* 8. Features Dropdown */}
                            <TextField
                                select
                                label="Feature"
                                name="featureId"
                                fullWidth
                                value={promotionFormData.featureId || ''}
                                onChange={(e) => setPromotionFormData({ ...promotionFormData, featureId: parseInt(e.target.value) })}
                                sx={{ mt: 2 }}
                                error={Boolean(featureIdError)}
                                helperText={featureIdError}
                            >
                                {features.map((feature) => (
                                    <MenuItem key={feature.featureId} value={feature.featureId}>
                                        {feature.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            {/* 9. Stores Number Input */}
                            <TextField
                                label="Stores"
                                name="stores"
                                type="number"
                                fullWidth
                                value={promotionFormData.stores}
                                onChange={(e) => setPromotionFormData({ ...promotionFormData, stores: parseInt(e.target.value) })}
                                sx={{ mt: 2 }}
                                error={Boolean(storesError)}
                                helperText={storesError}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogs}>Cancel</Button>
                    <Button onClick={() => handleUpsertPromotion(promotionFormData)}>
                        {isEditMode ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>


            {selectedPromotion && selectedPromotion.promotionTypeId !== 2 && (
                <Box sx={{height: 'auto', width: '100%', mt: 4}}>
                    <DataGrid
                        getRowId={(row) => row.promotionProductId}
                        rows={selectedPromotion.promotionProducts}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    promotionProductId: false,
                                    skuBaseForecastId: false,
                                },
                            },
                        }}
                        columns={promotionProductColumns}
                        pageSize={5}
                        autoHeight
                        slots={{
                            toolbar: PromoProductEditToolbar,
                        }}
                        slotProps={{
                            toolbar: {title: "Promos - Sku Forecast"},
                        }}
                        onRowClick={handlePromotionProductRowClick}
                        processRowUpdate={processPromotionProductRowUpdates}
                        onProcessRowUpdateError={handleProcessPromotionProductRowUpdateError}
                        editMode="row"
                    />
                    <Dialog open={openPromotionProductDialog} onClose={handleClose}>
                        <DialogTitle>Create Promotion Product</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{pb: 2}}>
                                Enter the details for the new Promotion Product.
                            </DialogContentText>
                            <Box sx={{pb: 2}}>
                                <ForecastProductMultiSelectDropdown
                                    forecastId={forecastId}
                                    customerId={customerId}
                                    forecastPeriodId={forecastPeriodId}
                                    onProductsSelected={handleProductsSelected}
                                    productErrorText={skuBaseForecastErrorText}
                                />
                            </Box>
                            <Box sx={{pb: 2}}>
                                <TextField
                                    label="RRP"
                                    name="recommendedRetailPrice"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={promotionProductFormData.recommendedRetailPrice}
                                    error={Boolean(recommendedRetailPriceErrorText)}
                                    helperText={recommendedRetailPriceErrorText}
                                    onChange={handleTextFieldChange}
                                />
                            </Box>
                            <Box sx={{pb: 2}}>
                                <TextField
                                    label={selectedPromotion.promotionTypeId === 3 ? "Promotional Cost Price" : "Unit Funding"}
                                    name="unitFunding"
                                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                    value={promotionProductFormData.unitFunding}
                                    error={Boolean(unitFundingErrorText)}
                                    helperText={unitFundingErrorText}
                                    onChange={handleTextFieldChange}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={() => handleCreatePromoProduct(promotionProductFormData)}>
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmingDeletionType === 'promotion'
                            ? 'Are you sure you want to delete this promotion? This action cannot be undone.'
                            : 'Are you sure you want to remove this SKU from its promotion? This action cannot be undone.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDialog} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (confirmingDeletionType === 'promotion') {
                                handleConfirmDeletePromotion();
                            } else if (confirmingDeletionType === 'promotionProduct') {
                                handleConfirmDeletePromotionProduct();
                            }
                        }}
                        color="primary"
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
