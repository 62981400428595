import React, {useEffect, useState} from 'react';
import {
    DataGrid,
    GridToolbarContainer
} from '@mui/x-data-grid';
import {
    IconButton,
    Box,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Select,
    MenuItem,
    Divider,
    Checkbox,
    FormControlLabel,
    Alert
} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../AxiosInstance";
import convertDateStringToDate, {formatDate, getCurrentDate} from '../Utilities/dateUtility';
import {isMoneyGreaterThanZero, validateStartDate} from "../Utilities/validation";

const defaultColumnWidths = {
    skuDescription: 100
};

function EditToolbar(props) {
    const {
        rows,
        selectedProduct,
        setRows,
        setSnackbar,
        onAdd,
        onDelete,
        onEdit
    } = props;

    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
            <IconButton aria-label="createProduct" onClick={onAdd}>
                <AddIcon/>
            </IconButton>
            <IconButton aria-label="edit" onClick={onEdit} disabled={!selectedProduct}>
                <EditIcon/>
            </IconButton>
            <IconButton aria-label="delete" onClick={onDelete} disabled={!selectedProduct}>
                <DeleteIcon/>
            </IconButton>
        </GridToolbarContainer>
    );
}

function ProductTable() {
    const [rows, setRows] = useState([]);
    const [snackbar, setSnackbar] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

    // Dialog states
    const [dialogOpen, setDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false); // New dialog for creating products
    const [futureWeeksOptions, setFutureWeeksOptions] = useState([]);
    const [editData, setEditData] = useState({
        endDate: '',
        unitCogs: '',
        unitCogsStartDate: ''
    });

    const resetCreateData = () => {
        setCreateData({
            skuNumber: '',
            skuDescription: '',
            brand: '',
            unitCogs: '',
            startDate: getCurrentDate()
        });
    };

    // State for Create Dialog form
    const [createData, setCreateData] = useState({
        skuNumber: '',
        skuDescription: '',
        brand: '',
        unitCogs: '',
        startDate: getCurrentDate()
    });

    // Error texts for Create Dialog form
    const [createErrorTexts, setCreateErrorTexts] = useState({
        skuNumber: '',
        skuDescription: '',
        brand: '',
        unitCogs: '',
        startDate: ''
    });

    const [clearFutureCogs, setClearFutureCogs] = useState(false); // New state for checkbox

    const [endDateErrorText, setEndDateErrorText] = useState('');
    const [unitCogsErrorText, setUnitCogsErrorText] = useState('');
    const [unitCogsStartDateErrorText, setUnitCogsStartDateErrorText] = useState('');

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        axiosInstance.get('/products')
            .then((response) => {
                const productsData = response.data.map(product => ({
                    ...product
                }));
                setRows(productsData);
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });
    };

    const handleAddClick = () => {
        setCreateDialogOpen(true); // Open Create Product dialog
    };

    const handleDeleteClick = async () => {
        if (!selectedProduct) return;

        try {
            await axiosInstance.delete(`/products/${selectedProduct.productId}`);
            setRows((prevRows) => prevRows.filter((row) => row.productId !== selectedProduct.productId));
            setSnackbar({children: 'Product successfully deleted', severity: 'success'});
        } catch (error) {
            setSnackbar({children: 'Error deleting product', severity: 'error'});
        }
    };

    const handleEditClick = async () => {
        if (!selectedProduct) return;

        setEditData({
            endDate: selectedProduct.endDate ? formatDateForInput(selectedProduct.endDate) : '',
            unitCogs: selectedProduct.futureUnitCogs || '',
            unitCogsStartDate: selectedProduct.futureUnitCogsStartDate
        });

        try {
            const response = await axiosInstance.get('/calendar');
            setFutureWeeksOptions(response.data);
        } catch (error) {
            console.error('Error fetching future weeks options:', error);
        }

        setDialogOpen(true);
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toISOString().slice(0, 10); // Only keep 'yyyy-MM-dd'
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        resetErrorMessages();
        setClearFutureCogs(false); // Reset checkbox state
    };

    const resetErrorMessages = () => {
        setEndDateErrorText('');
        setUnitCogsErrorText('');
        setUnitCogsStartDateErrorText('');
    };

    const validateFields = () => {
        let isValid = true;

        let endDateProvided = !!editData.endDate;
        let unitCogsProvided = !!editData.unitCogs;
        let unitCogsStartDateProvided = !!editData.unitCogsStartDate;

        const currentDate = new Date();
        const startDate = new Date(selectedProduct.startDate); // Get the product's start date
        const endDate = endDateProvided ? new Date(editData.endDate) : null; // Get the provided end date
        const unitCogsStartDate = unitCogsStartDateProvided ? convertDateStringToDate(editData.unitCogsStartDate) : null; // Unit COGS Start Date

        // Prevent editing of expired products
        if (selectedProduct.endDate && new Date(selectedProduct.endDate) < currentDate) {
            setSnackbar({children: 'An expired product cannot be edited', severity: 'error'});
            handleDialogClose();
            return false;
        }

        // Check if the end date is before the current date (past date)
        if (endDateProvided && endDate < currentDate) {
            setEndDateErrorText('Product End Date cannot be set to a date in the past');
            isValid = false;
        } else if (endDateProvided && endDate < startDate) {
            // Check if the end date is before the start date
            setEndDateErrorText('End Date cannot be earlier than Start Date');
            isValid = false;
        } else {
            setEndDateErrorText(''); // Clear error if valid
        }

        // Validate Unit COGS and start date for COGS
        if (!clearFutureCogs) {
            if (unitCogsStartDateProvided && !unitCogsProvided) {
                setUnitCogsErrorText('Unit COGS is required when Unit COGS Start Date is provided');
                isValid = false;
            }

            if (unitCogsProvided && !unitCogsStartDateProvided) {
                setUnitCogsStartDateErrorText('Unit COGS Start Date is required when Unit COGS is provided');
                isValid = false;
            }

            if (unitCogsProvided && isNaN(editData.unitCogs)) {
                setUnitCogsErrorText('Unit COGS must be a number');
                isValid = false;
            }
            
            // New validation: Ensure Unit COGS Start Date is not after the Product End Date
            if (unitCogsStartDateProvided && endDateProvided && unitCogsStartDate > endDate) {
                setUnitCogsStartDateErrorText('Unit COGS Start Date cannot be after the Product End Date');
                isValid = false;
            } else {
                setUnitCogsStartDateErrorText(''); // Clear error if valid
            }
        }

        return isValid;
    };



    const handleDialogSubmit = async () => {
        if (!validateFields()) {
            return;
        }

        setDialogOpen(false);

        const updateRequest = {
            futureUnitCogs: clearFutureCogs ? null : (editData.unitCogs ? parseFloat(editData.unitCogs) : null),
            futureUnitCogsStartDate: clearFutureCogs ? null : editData.unitCogsStartDate,
            endDate: editData.endDate ? new Date(editData.endDate) : null
        };

        try {
            await axiosInstance.put(`/products/${selectedProduct.productId}`, updateRequest);

            setRows((prevRows) =>
                prevRows.map((row) =>
                    row.productId === selectedProduct.productId
                        ? {
                            ...row,
                            ...updateRequest,
                            productId: selectedProduct.productId,
                            futureUnitCogsStartDate: updateRequest.futureUnitCogsStartDate
                        }
                        : row
                )
            );

            setSnackbar({children: 'Product successfully updated', severity: 'success'});
        } catch (error) {
            setSnackbar({children: 'Error updating product', severity: 'error'});
        }
    };

    const handleCreateDialogClose = () => {
        setCreateDialogOpen(false);
        resetCreateData();
        resetCreateErrorMessages();
    };

    const resetCreateErrorMessages = () => {
        setCreateErrorTexts({
            skuNumber: '',
            skuDescription: '',
            brand: '',
            unitCogs: '',
            startDate: ''
        });
    };

    const validateCreateFields = () => {
        const errors = {...createErrorTexts};
        let isValid = true;

        if (!createData.skuNumber) {
            errors.skuNumber = 'SKU Number is required';
            isValid = false;
        } else {
            errors.skuNumber = '';
        }

        if (!createData.skuDescription) {
            errors.skuDescription = 'Description is required';
            isValid = false;
        } else {
            errors.skuDescription = '';
        }

        if (!createData.brand) {
            errors.brand = 'Brand is required';
            isValid = false;
        } else {
            errors.brand = '';
        }

        const isValidUnitCogs = isMoneyGreaterThanZero(createData.unitCogs);
        if (!isValidUnitCogs) {
            errors.unitCogs = 'Unit COGS must be a positive number';
            isValid = false;
        } else {
            errors.unitCogs = '';
        }

        const isValidStartDate = validateStartDate(createData.startDate);
        if (!isValidStartDate) {
            errors.startDate = 'Start Date cannot be in the past';
            isValid = false;
        } else {
            errors.startDate = '';
        }

        setCreateErrorTexts(errors);
        return isValid;
    };

    const handleCreateDialogSubmit = async () => {
        if (!validateCreateFields()) {
            return;
        }

        const newProduct = {
            skuNumber: createData.skuNumber,
            skuDescription: createData.skuDescription,
            brand: createData.brand,
            unitCogs: parseFloat(createData.unitCogs),
            startDate: createData.startDate
        };

        try {
            const response = await axiosInstance.post('/products', newProduct);
            const createdRow = response.data;

            setRows((prevRows) => [createdRow, ...prevRows]);

            resetCreateData();
            setCreateDialogOpen(false);
            resetCreateErrorMessages();
            setSnackbar({ children: 'Product successfully created', severity: 'success' });
        } catch (error) {
            // Capture and display error details
            const errorMessage = error.response?.data || 'An error occurred while creating the product.';
            setSnackbar({ children: errorMessage, severity: 'error' });
        }
    };


    const handleRowSelection = (selectionModel) => {
        if (selectionModel.length > 0) {
            const selectedId = selectionModel[0];
            const selectedRow = rows.find(row => row.productId.toString() === selectedId.toString());
            setSelectedProduct(selectedRow);
        } else {
            setSelectedProduct(null);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar(null);
    };

    const columns = [
        {field: 'productId', headerName: 'ID', width: 50},
        {field: 'skuNumber', headerName: 'SKU', width: 100},
        {field: 'skuDescription', headerName: 'Description', width: columnWidths.skuDescription},
        {field: 'brand', headerName: 'Brand', width: 100},
        {field: 'hierarchyLevel1', headerName: 'Hierarchy L1', width: 100},
        {field: 'hierarchyLevel2', headerName: 'Hierarchy L2', width: 100},
        {field: 'hierarchyLevel3', headerName: 'Hierarchy L3', width: 100},
        {
            field: 'unitCogs',
            headerName: 'Unit COGS',
            type: 'number',
            width: 100,
            valueFormatter: (value) => {
                return '£' + parseFloat(value).toFixed(2);
            },
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            type: 'date',
            width: 100,
            valueFormatter: (value) => formatDate(value)
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            type: 'date',
            width: 100,
            valueFormatter: (value) => {
                if (!value) {
                    return '';
                }
                return formatDate(value);
            }
        },
        {
            field: 'futureUnitCogs',
            headerName: 'Future Unit COGS',
            type: 'number',
            width: 100,
            valueFormatter: (value) => {
                if (!value) {
                    return '';
                }
                return '£' + parseFloat(value).toFixed(2);
            },
        },
        {
            field: 'futureUnitCogsStartDate',
            headerName: 'Future Unit COGS Start Date',
            type: 'date',
            width: 100,
            valueFormatter: (value) => {
                if (!value) {
                    return '';
                }
                return value;
            }
        }
    ];

    return (
        <Box sx={{height: '100%', width: '100%'}}>
            <DataGrid
                getRowId={(row) => row.productId}
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            productId: false,
                            hierarchyLevel1: false,
                            hierarchyLevel2: false,
                            hierarchyLevel3: false
                        },
                    },
                }}
                pageSize={10}
                rowSelectionModel={selectedProduct ? [selectedProduct.productId] : []}
                onRowSelectionModelChange={handleRowSelection}
                slots={{
                    toolbar: EditToolbar
                }}
                slotProps={{
                    toolbar: {
                        rows,
                        selectedProduct,
                        setRows,
                        setSnackbar,
                        onAdd: handleAddClick,
                        onDelete: handleDeleteClick,
                        onEdit: handleEditClick
                    }
                }}
                autoHeight
            />

            {/* Edit Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Edit Product</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Product End Date"
                        type="date"
                        value={editData.endDate}
                        onChange={(e) => setEditData({...editData, endDate: e.target.value})}
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        margin="dense"
                        error={Boolean(endDateErrorText)}
                        helperText={endDateErrorText}
                    />
                    <Divider sx={{my: 2}}/>
                    <FormControlLabel
                        control={<Checkbox checked={clearFutureCogs}
                                           onChange={(e) => setClearFutureCogs(e.target.checked)}/>}
                        label="Clear future unit cogs"
                    />
                    <TextField
                        label="Unit COGS"
                        type="number"
                        value={editData.unitCogs}
                        onChange={(e) => setEditData({...editData, unitCogs: e.target.value})}
                        fullWidth
                        margin="dense"
                        disabled={clearFutureCogs}
                        error={Boolean(unitCogsErrorText)}
                        helperText={unitCogsErrorText}
                    />
                    <TextField
                        label="Unit COGS Start Date"
                        select
                        value={editData.unitCogsStartDate || ''}
                        onChange={(e) => setEditData({...editData, unitCogsStartDate: e.target.value})}
                        fullWidth
                        margin="dense"
                        disabled={clearFutureCogs}
                        error={Boolean(unitCogsStartDateErrorText)}
                        helperText={unitCogsStartDateErrorText}
                    >
                        {futureWeeksOptions.map((week) => (
                            <MenuItem key={week} value={week}>
                                {week}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
                    <Button onClick={handleDialogSubmit} color="primary">OK</Button>
                </DialogActions>
            </Dialog>

            {/* Create Dialog */}
            <Dialog open={createDialogOpen} onClose={handleCreateDialogClose}>
                <DialogTitle>Create Product</DialogTitle>
                <DialogContent>
                    <TextField
                        label="SKU"
                        value={createData.skuNumber}
                        onChange={(e) => setCreateData({...createData, skuNumber: e.target.value})}
                        fullWidth
                        margin="dense"
                        slotProps={{ htmlInput: { maxLength: 50 } }}
                        error={Boolean(createErrorTexts.skuNumber)}
                        helperText={createErrorTexts.skuNumber}
                    />
                    <TextField
                        label="Description"
                        value={createData.skuDescription}
                        onChange={(e) => setCreateData({...createData, skuDescription: e.target.value})}
                        fullWidth
                        margin="dense"
                        slotProps={{ htmlInput: { maxLength: 100 } }}
                        error={Boolean(createErrorTexts.skuDescription)}
                        helperText={createErrorTexts.skuDescription}
                    />
                    <TextField
                        label="Brand"
                        value={createData.brand}
                        onChange={(e) => setCreateData({...createData, brand: e.target.value})}
                        fullWidth
                        margin="dense"
                        slotProps={{ htmlInput: { maxLength: 50 } }}
                        error={Boolean(createErrorTexts.brand)}
                        helperText={createErrorTexts.brand}
                    />
                    <TextField
                        label="Unit COGS"
                        type="number"
                        value={createData.unitCogs}
                        onChange={(e) => setCreateData({...createData, unitCogs: e.target.value})}
                        fullWidth
                        margin="dense"
                        error={Boolean(createErrorTexts.unitCogs)}
                        helperText={createErrorTexts.unitCogs}
                    />
                    <TextField
                        label="Start Date"
                        type="date"
                        value={createData.startDate}
                        onChange={(e) => setCreateData({...createData, startDate: e.target.value})}
                        fullWidth
                        margin="dense"
                        error={Boolean(createErrorTexts.startDate)}
                        helperText={createErrorTexts.startDate}
                        InputLabelProps={{shrink: true}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateDialogClose} color="secondary">Cancel</Button>
                    <Button onClick={handleCreateDialogSubmit} color="primary">Create</Button>
                </DialogActions>
            </Dialog>

            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleSnackbarClose}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleSnackbarClose}/>
                </Snackbar>
            )}
        </Box>
    );
}

export default ProductTable;
