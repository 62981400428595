import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, TextField, Button, Typography, Paper, Snackbar, Alert, Link} from '@mui/material';
import {useAuth} from '../AuthContext';
import Box from "@mui/material/Box";

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {isAuthenticated, handleLogin} = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email, password}),
            });

            if (response.ok) {
                const data = await response.json();
                handleLogin(email, data.token);
                navigate('/');
            } else {
                const result = await response.json();
                setErrorMessage(result.message || 'The username or password is incorrect.');
                setErrorSnackbarOpen(true);
            }
        } catch (error) {
            console.log('error: ', error);
            setErrorMessage('An unexpected error occurred. Please try again.');
            setErrorSnackbarOpen(true);
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <Container component="main" maxWidth="xs" className="component-container">
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign In
                </Typography>
                <form onSubmit={handleSubmit} style={{width: '100%', marginTop: '8px'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{margin: '24px 0 16px'}}
                    >
                        Sign In
                    </Button>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={handleForgotPassword}
                        style={{marginTop: '8px'}}
                    >
                        Forgot Password?
                    </Link>
                </form>
            </Paper>
            <Box
                sx={{
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    textAlign: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {`© ${new Date().getFullYear()} AbakusIQ Ltd`}
                </Typography>
            </Box>
            <Snackbar
                open={errorSnackbarOpen}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={6000}
                onClose={() => setErrorSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setErrorSnackbarOpen(false)}
                    severity="error"
                    sx={{width: '100%'}}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default LoginPage;
