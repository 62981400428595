export const getChangedWeeklyDistributionRates = (newRates, oldRates) => {
    const changes = [];
    for (const newRate of newRates) {
        const oldRate = oldRates.find(rate => rate.week === newRate.week); // Match by week
        if (oldRate) {
            const oldValue = oldRate.actualDistributionRate;
            const newValue = newRate.actualDistributionRate ?? 0;

            if (!isNaN(oldValue) && !isNaN(newValue) && newValue !== oldValue) {
                changes.push({ week: newRate.week, actualDistributionRate: newValue });
            }
        }
    }

    return changes;
};
